import { AxiosError } from 'axios';
import SdkError from '../../errors/SdkError';
import { HttpInstance } from '../../types';
import LockModule from './lock/lock';
import { Store } from './store.types';

/**
 * Modulo de Lojas da API
 */
export default (httpClient: HttpInstance) => ({
  /**
   * Lock
   */
  lock: LockModule(httpClient),
  /**
   * Busca lojas próximas a localização do usuário, ordenadas por distância
   *
   * @param {Store.SearchRequest} args Argumentos da busca
   */
  search: async (args: Store.SearchRequest) => {
    const params = await Store.searchRequestSchema.parseAsync(args).catch((err) => {
      throw new SdkError('VALIDATION_ERROR', err);
    });
    const { data } = await httpClient.get<Store.SearchResponse>('/app/v2/stores', { params }).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });
    return data;
  },
  /**
   * Consulta uma loja pelo ID
   * @param {number} id ID da loja
   */
  get: async (id: Store.GetRequest) => {
    const { data } = await httpClient.get<Store.GetResponse>(`/store/${id}`);
    return data;
  },
  /**
   * Pegar a última compra do usuário
  */
  lastPurchase: async () => {
    const { data } = await httpClient.get<Store.LastPurchaseResponse>('/v2/client/last-purchase').catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });

    return data;
  },
});
