import { AxiosError } from 'axios';
import SdkError from '../../errors/SdkError';
import { HttpInstance } from '../../types';
import AdvertModule from './advert/advert';
import CategoryModule from './category/category';
import FavoriteModule from './favorite/favorite';
import { Product } from './product.types';

/**
 * Modulo de busca de produto por categoria da API
 */
export default (httpClient: HttpInstance) => ({
  /**
   * Favoritos
   */
  favorite: FavoriteModule(httpClient),
  /**
   * Categorias
   */
  category: CategoryModule(httpClient),
  /**
   * Anúncios
   */
  advert: AdvertModule(httpClient),

  /**
   * Busca produtos com ofetas de uma loja
   *
   * @param {Product.SoldoutRequest} args Argumentos da busca
   */
  soldout: async (args: Product.SoldoutRequest) => {
    const params = await Product.soldoutRequestSchema.parseAsync(args).catch((err) => {
      throw new SdkError('VALIDATION_ERROR', err);
    });

    const {
      storeId, perPage, page,
    } = params;

    const { data } = await httpClient.get<Product.SoldoutResponse>(
      `/app/v2/planogram/${storeId}/soldout?page=${page}&perPage=${perPage}`,
    ).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });
    return data;
  },

  /**
   * Busca produtos do compre de novo de uma loja
   *
   * @param {Product.BuyagainRequest} args Argumentos da busca
   */
  buyagain: async (args: Product.BuyagainRequest) => {
    const params = await Product.buyagainRequestSchema.parseAsync(args).catch((err) => {
      throw new SdkError('VALIDATION_ERROR', err);
    });

    const {
      storeId, perPage, page,
    } = params;

    const { data } = await httpClient.get<Product.BuyAgainResponse>(
      `/app/v2/planogram/${storeId}/buyagain?page=${page}&perPage=${perPage}`,
    ).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });
    return data;
  },

  /**
   * Busca produtos por categoria de uma loja
   *
   * @param {Product.Category} args Argumentos da busca
   */
  productByCategory: async (args: Product.CategoryRequest) => {
    const params = await Product.categoryRequestSchema.parseAsync(args).catch((err) => {
      throw new SdkError('VALIDATION_ERROR', err);
    });

    const {
      storeId, categoryId, perPage, page,
    } = params;

    const { data } = await httpClient.get<Product.CategoryResponse>(
      `/app/v2/planogram?page=${page}&perPage=${perPage}&categoryId=${categoryId}&storeId=${storeId}`,
    ).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });
    return data;
  },

  /**
  * Busca produtos pelo ID
  *
  * @param {Product.GetRequest} args Argumentos da busca
  */

  get: async (args: Product.GetRequest) => {
    const params = await Product.productRequestSchema.parseAsync(args).catch((err) => {
      throw new SdkError('VALIDATION_ERROR', err);
    });

    const {
      storeId, productId,
    } = params;

    const { data } = await httpClient.get<Product.GetProductResponse>(
      `/product/${productId}/store/${storeId}/planogram`,
    ).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });
    return data;
  },
  /**
  * Busca produto código de barras
  *
  * @param {Product.ParamsSearchByBarcode} args Argumentos da busca
  */

  searchProductByBarCode: async (args: Product.ParamsSearchByBarcode) => {
    const params = await Product.searchProductByBarcodeSchema.parseAsync(args).catch((err) => {
      throw new SdkError('VALIDATION_ERROR', err);
    });

    const {
      storeId, barCode,
    } = params;

    const { data } = await httpClient.get<Product.ProductByBarcodeResponse>(
      `/store/${storeId}/planogram/barcode/${barCode}`,
    ).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });
    return data;
  },
  /**
  * Busca produtos a partir de uma lojá
  * @param {Product.ProductSearchRequest} args Argumentos da busca
 */
  searchProductByStore: async (args: Product.ProductSearchRequest) => {
    const params = await Product.productSearchRequestSchema.parseAsync(args).catch((err) => {
      throw new SdkError('VALIDATION_ERROR', err);
    });
    const { data } = await httpClient.get<Product.SearchResponse>(`client/store/${params.storeId}/planogram/search-paginated/${params.productName}`).catch((err: AxiosError) => {
      throw new SdkError('HTTP_ERROR', err);
    });

    return data;
  },
});
