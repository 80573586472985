import * as z from 'zod';
import cpf from '../../utils/cpf';
import { Prettify } from '../../utils/pretify';
import string from '../../utils/string';

export namespace Session {

  export class SessionError extends Error {
    constructor(message: Record<string, string> | string) {
      super(JSON.stringify(message));
      this.name = 'SessionError';
    }

    get messages() {
      return JSON.parse(this.message);
    }
  }

  // General types
  export interface Token {
    expires_at: string
    token: string
    type: string
  }

  export interface Client {
    id: number
    user_id: number
    is_blocked: number
    name: string
    cpf: string
    date_birth: string
    phone: string
    created_at: string
    updated_at: string
    profile_image: any
    provider_id: any
    provider: string
  }

  export interface ShopManager {
    id: number
    user_id: number
    is_blocked: number
    name: string
    type_pix: any
    pix: any
    doc: string
    type_doc: string
    phone: string
    created_at: string
    updated_at: string
    trading_name: string
    municipal_inscription: any
    state_inscription: any
    digital_certificate_password: any
    tax_regime: any
    address_id: number
    iugu_live_api_token: null | string
    iugu_test_api_token: null | string
    iugu_user_token: null | string
    focus_id: null | string
    percentage: number
    id_token_nfce_production: null | string
    emit_invoice: number
    csc_nfce_production: any
    current_reference: number
    verified_iugu_account: any
    verified_shopmanager: number
    recipient_id: string
    antecipation: any
    active_pwa: number
    active_app: number
    active_pagarme: string
    active_fraud_prevention: number
    use_plugpag: number
    plugpag_email: null | string
    plugpag_token: null | string
    pagbank_split_percentage: number
    monthly_fee_type: string
    monthly_fee_value: number
    focus_nfe_token: null | string
    focus_webhook_id: null | string
  }

  export interface User {
    created_at: string
    email: string
    id: number
    is_confirmed: number
    is_default_payment_id: any
    iugu_id: any
    notification_token: string
    pagarme_id: string
    provider_apple: string
    provider_facebook: string
    provider_google: string
    updated_at: string
    client: Client
    shopManager: ShopManager
  }

  export interface Role {
    type: string
  }

  /**
 * Sign
 */

  export const signinSchema = z.object({
    email: z.string()
      .min(1, 'Por favor, informe um email')
      .email('Por favor, informe um email válido'),
    password: z.string()
      .min(1, 'Por favor, Informe sua senha'),
  });

  export type SigninRequest = Prettify<z.infer<typeof signinSchema>>;

  export type SigninResponse = Prettify<{
    roles: Role[]
    token: Token
    user: User
  }>;

  /**
   * Sign with Facebook
   */

  export type SigninWithSocialRequest = Prettify<string>;

  export type SigninWithSocialResponse = Prettify<{
    token: Token;
    user: User;
    id: number | null
  }>;

  /**
 * Current Session Status
 */

  export type StatusRequest = Prettify<{}>;

  export type StatusResponse = Prettify<{
    roles: Role[]
    user: User
  }>;

  /**
 * Logout
 */

  export type LogoutRequest = Prettify<{}>;

  export type LogoutResponse = Prettify<{
    success: boolean
  }>;

  /**
   * Recover Password
   */

  export const recoverPasswordSchema = z.object({
    email: z.string()
      .min(1, 'Por favor, informe um email')
      .email('Por favor, informe um email válido'),
  });

  export type RecoverPasswordRequest = z.infer<typeof recoverPasswordSchema>;

  export interface RecoverPasswordResponse {
    token: string
  }

  /**
   * Register
   */

  export const registerSchema = z.object({
    cpf: z.string({ required_error: 'Por favor, informe um CPF' })
      .min(1, 'Por favor, informe um CPF')
      .transform(string.cleanNumber)
      .refine(cpf.isValid, 'Por favor, informe um CPF válido'),
    dateBirth: z.string()
      .min(1, 'Por favor, informe uma data de nascimento'),
    email: z.string()
      .min(1, 'Por favor, informe um email')
      .email('Por favor, informe um email válido'),
    name: z.string().min(1, 'Por favor, informe um nome'),
    phone: z.string().min(1, 'Por favor, informe um telefone'),
    profileImage: z.string().optional(),
    provider: z.enum(['apple', 'facebook', 'google', 'email']),
    providerId: z.string().optional(),
    acceptTerms: z.boolean().refine(Boolean, 'Por favor, aceite os termos de uso'),
    id: z.number().optional(),
  });

  export type RegisterRequest = z.infer<typeof registerSchema>;

  export interface RegisterResponse {
    token: Token
    user: User
  }

  /**
   * Resend Confirmation
   */
  export interface ResendConfirmationRequest {
    email: string
  }

  export interface ResendConfirmationResponse {
    success: boolean
  }
}
