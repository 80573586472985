import axios, { AxiosInstance } from 'axios';
import checkModule from './check/check';
import customerModule from './customer/customer';
import notificationModule from './notification/notification';
import orderModule from './orders/orders';
import productModule from './product/product';
import sessionModule from './session/session';
import storeModule from './store/store';

import { ShoppbudAPIConfig } from '../types';

export * from './check/check.types';
export * from './customer/address/address.types';
export * from './customer/customer.types';
export * from './customer/feedback/feedback.types';
export * from './customer/payment/payment.types';
export * from './notification/notification.types';
export * from './orders/orders.types';
export * from './product/advert/advert.types';
export * from './product/category/category.types';
export * from './product/favorite/favorite.types';
export * from './product/product.types';
export * from './session/session.types';
export * from './store/lock/lock.types';
export * from './store/store.types';

export class ShoppbudAPI {
  private readonly http: AxiosInstance;

  public readonly session: ReturnType<typeof sessionModule>;

  public readonly store: ReturnType<typeof storeModule>;

  public readonly product: ReturnType<typeof productModule>;

  public readonly order: ReturnType<typeof orderModule>;

  public readonly customer: ReturnType<typeof customerModule>;

  public readonly notification: ReturnType<typeof notificationModule>;

  public readonly check: ReturnType<typeof checkModule>;

  constructor(config: ShoppbudAPIConfig) {
    this.http = axios.create({
      baseURL: config.url,
      timeout: config.timeout || 30 * 1000,
      headers: {
        'Content-Type': 'application/json',
        ...(config.token && { Authorization: `Bearer ${config.token}` }),
        ...(config.platformAndVersion && { 'x-platform': config.platformAndVersion }),
        ...(config.appVersion && { 'x-app-version': config.appVersion }),
        ...(config.userId && { 'x-user-id': config.userId }),
      },
    });

    this.http.interceptors.request.use(async (request) => {
      if (config.getTokenFn) {
        const token = await config.getTokenFn();
        request.headers.Authorization = `Bearer ${token}`;
      }
      if (config.getDeviceId) {
        const deviceId = await config.getDeviceId();
        request.headers['x-device-id'] = deviceId;
      }

      return request;
    });

    this.session = sessionModule(this.http);
    this.store = storeModule(this.http);
    this.product = productModule(this.http);
    this.order = orderModule(this.http);
    this.customer = customerModule(this.http);
    this.notification = notificationModule(this.http);
    this.check = checkModule(this.http);
  }

  setToken(token: string) {
    this.http.defaults.headers.Authorization = `Bearer ${token}`;
  }

  clearToken() {
    delete this.http.defaults.headers.Authorization;
  }

  async get<T>(url: string, params?: any): Promise<T> {
    const { data } = await this.http.get<T>(url, { params });
    return data;
  }

  async post<T>(url: string, body?: any): Promise<T> {
    const { data } = await this.http.post<T>(url, body);
    return data;
  }

  async put<T>(url: string, body?: any): Promise<T> {
    const { data } = await this.http.put<T>(url, body);
    return data;
  }
}
